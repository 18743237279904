.detail-container {
  display: grid;
  gap: 20px; /* Espacio entre las dos columnas */
  grid-template-columns: 1fr; /* Una sola columna en pantallas pequeñas */
}

.image-wrapper-detail {
  position: relative;
  overflow: hidden;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.zoomable-image-detail {
  width: 100%;
  opacity: 0;
}

.description {
  padding: 20px;
  margin: 20px; /* Margen alrededor del texto */
}

.description h2 {
  margin-bottom: 10px;
}

.description p {
  font-size: 25px;
  line-height: 1.6;
}

.ip-add-cart-inr {
  border: none;
  padding: 12px 50px;
  background: #51AC4F;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 25px;
  box-shadow: 0px 15px 45px rgba(242, 45, 52, 0.5);
  cursor: pointer;
  transition: 0.3s ease-in;
  text-align: center;
}

.add-to-cart-container-detail {
  text-align: center;
}

.toast-container {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 9999;
}


@media (min-width: 768px) {
  .detail-container  {
    grid-template-columns: 1fr 1fr; /* Divide en dos columnas de igual tamaño */
    grid-template-rows: auto auto; /* Dos filas automáticas */
  }
}


@media (min-width: 992px) {
  .detail-container {
    margin: 40px;
    grid-template-columns: 1fr 1fr; /* Divide en dos columnas de igual tamaño */
    margin-left: 70px;

  }
  .add-to-cart-container-detail {
    padding-top: 250px;

  }
}