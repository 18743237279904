.subcategory-list-lv2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

body {
  
  justify-content: center;
  align-items: center;
  height: none;
  
}

.container-card {
  position: relative;
  margin: 70px auto;
  width: 350px;
  height: 380px;
  text-align: center;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1), 0px 60px 75px -25px rgba(0, 0, 0, 0.2);
}

.container-card .img-cart {
  width: 290px;
  margin-top: -60px;
  animation: flotar 1.2s ease-in-out infinite;
}




/* Media query para pantallas grandes */
@media (min-width: 576px) {
  .subcategory-item-list {
    flex: 0 0 calc(100% - 20px);
    /* Ajusta el ancho según tus necesidades */
    margin: 10px;
    text-align: center;
  }
}

@media (min-width: 768px) {
  .subcategory-item-list {
    flex: 0 0 calc(50% - 20px);
    /* Ajusta el ancho según tus necesidades */
    margin: 10px;
    text-align: center;
  }
}

@media (min-width: 992px) {
  .subcategory-item-list {
    flex: 0 0 calc(33.333% - 20px);
    /* Ajusta el ancho según tus necesidades */
    margin: 10px;
    text-align: center;

  }
  .subcategory-list-lv2 {
    justify-content: start;
  }

}