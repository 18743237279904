.titleCategory {
  padding-top: "100px";
  text-align: "center";
  font-size: 2em;
  font-weight: bold;
}

.product-item {
  text-align: center;
  /* Centra el contenido horizontalmente */
}

.card-img-top {
  display: block;
  /* Hace que la imagen sea un bloque */
  margin: 0 auto;
  /* Centra horizontalmente la imagen */
}

.slider-container {
  padding-top: 100px;
  text-align: center;
}

.slider-container .slick-prev,
.slider-container .slick-next {
  top: 50%;
  /* Alinea los botones al centro verticalmente */
  transform: translateY(-50%);
  /* Centra los botones verticalmente */
}


/* Media query para pantallas grandes */
@media (min-width: 576px) {
  .titleCategory {
    padding-top: 0px;
  }

  .slider-container {
    padding-top: 0px;
  }
}

@media (min-width: 768px) {
  .titleCategory {
    padding-top: 0px;
  }

  .slider-container {
    padding-top: 0px;
  }
}

@media (min-width: 992px) {
  .titleCategory {
    padding-top: 100px;
  }

  .slider-container {
    padding-top: 100px;
  }
}