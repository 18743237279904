.subcategory-list {
  display: flex;
  flex-wrap: wrap;
  margin: 30px;
  justify-content: flex-start;

}

.subcategory-item-catList {
  flex: 0 0 calc(100% - 20px);
  /* Ajusta el ancho según tus necesidades */
  margin: 10px;
  text-align: center;
}

.subcategory-thumbnail {
  width: 100%;
  max-width: 150px;
  /* Ajusta el tamaño de la miniatura según tus necesidades */
}

.subcategory-name {
  margin-top: 5px;
  font-size: 14px;
}


body {

  justify-content: center;
  align-items: center;
  height: 100vh;

}

.container-card-listProduct {
  position: relative;
  margin: 70px auto;
  width: 350px;
  height: 545px;
  text-align: center;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1), 0px 60px 75px -25px rgba(0, 0, 0, 0.2);
}

.container-card-listProduct .img-cart {
  width: 290px;
  margin-top: -90px;
  animation: flotar 1.2s ease-in-out infinite;
}


.container-card-listProduct h3 {
  font-size: 30px;
  letter-spacing: 3px;
  margin-top: 35px;
  color: #ea1d24;
}

.container-card-listProduct .control-btn span {
  position: absolute;
  width: 35px;
  height: 35px;
  background: #f22d34;
  border-radius: 100%;
  color: #fff;
  font-size: 13px;
  cursor: pointer;
  box-shadow: 0px 2px 7px rgba(241, 44, 51, 0.7);
  transition: 0.3s ease-in;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.container-card-listProduct .control-btn span:hover {
  background: #de2128;
}

.container-card-listProduct .control-btn .prev {
  left: -17.5px;
}

.container-card-listProduct .control-btn .next {
  right: -17.5px;
}


.container-card-listProduct .properties {
  margin-top: 20px;
  height: 130px;
  padding-bottom: 30px;
}

.container-card-listProduct .properties div {
  text-align: center;
  width: 100%;
  float: left;
}

.container-card-listProduct .properties div h4 {
  font-size: 14px;
  color: #757575;
  padding: 25px 0px;
}

.container-card-listProduct .properties .qty .fa {
  font-size: 7px;
  color: #757575;
  background: #dfdfdf;
  border-radius: 100%;
  padding: 4px;
  cursor: pointer;
}

.container-card-listProduct .properties .qty .number {
  font-size: 11px;
  color: #757575;
  padding: 5px;
}

.container-card-listProduct .properties .price-inr .fa {
  font-size: 18px;
  color: #757575;
}

.container-card-listProduct .properties .price-inr .amount {
  color: #51AC4F;
  font-size: 35px;
  font-weight: 700;
  font-family: "Open Sans";
  line-height: 20px;

}

.container-card-listProduct .properties .delivery .fa {
  font-size: 15px;
  color: #757575;
}

.container-card-listProduct .properties .delivery .time {
  display: inline-block;
  color: #757575;
  font-size: 11px;
}

.container-card-listProduct .ip-add-cart-inr {
  border: none;
  padding: 12px 50px;
  background: #51AC4F;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 25px;
  box-shadow: 0px 15px 45px rgba(242, 45, 52, 0.5);
  cursor: pointer;
  transition: 0.3s ease-in;
}

.container-card-listProduct .ip-add-cart:hover {
  background: #da1920;
}

.discount-porperties .discount{
  color: red;
}

.discount-porperties .priceBeforeTxt{
  color: #878787;
  font-size: 11px;
}
.discount-porperties .priceBefore{
  color: red;
  text-decoration: line-through;
  font-size: 11px;
}
.delivery{
  margin-bottom: 35px;
}


/* Media query para pantallas grandes */
@media (min-width: 576px) {
  .subcategory-item-list {
    flex: 0 0 calc(100% - 20px);
    /* Ajusta el ancho según tus necesidades */
    margin: 10px;
    text-align: center;
  }
}

@media (min-width: 768px) {
  .subcategory-list {
    justify-content: center;
  
  }
  .subcategory-item-list {
    flex: 0 0 calc(50% - 20px);
    /* Ajusta el ancho según tus necesidades */
    margin: 10px;
    text-align: center;
  }
}

@media (min-width: 992px) {
  .subcategory-item-list {
    flex: 0 0 calc(33.333% - 20px);
    /* Ajusta el ancho según tus necesidades */
    margin: 10px;
    text-align: center;
  }
}