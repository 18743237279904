.subcategory-list {
  display: flex;
  flex-wrap: wrap;
}

.subcategory-item-sub {
  flex: 0 0 calc(100% - 20px); /* Ajusta el ancho según tus necesidades */
  margin: 10px;
  text-align: center;
}






/* Media query para pantallas grandes */
@media (min-width: 576px) {
.subcategory-item-sub {
  flex: 0 0 calc(100% - 20px); /* Ajusta el ancho según tus necesidades */
  margin: 10px;
  text-align: center;
}
}

@media (min-width: 768px) {
.subcategory-item-sub {
  flex: 0 0 calc(50% - 20px); /* Ajusta el ancho según tus necesidades */
  margin: 10px;
  text-align: center;
}
}
@media (min-width: 992px) {
.subcategory-item-sub {
  flex: 0 0 calc(33.333% - 20px); /* Ajusta el ancho según tus necesidades */
  margin: 10px;
  text-align: center;
}
}
