:root {
  --primary-color: #333;
  --secondary-color: #444;
  --alt-color: #af3231;
  --overlay: rgba(0, 0, 0, 0.8);
  --container-with: 90vw;

}

.nav-wrapper {
  padding: 10px 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.navbar {
  display: flex;
  justify-content: space-between;
  /* Divide el espacio horizontalmente */
  align-items: center;
  background: none;
  box-shadow: none;
  height: 40px;
  /* Centra verticalmente los elementos */
}

.left-section {
  flex: 1;
}

.right-section {
  display: flex;
}





.navbar-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.navbar-item {
  display: inline;
  margin-right: 20px;
}

.navbar-link {
  color: var(---colooverlayr);
  text-decoration: none;
}



.container {
  margin: 0 auto;
  overflow: hidden;
  padding: 0 2rem;
  
}

.badge {
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 5px 8px;
  font-size: 12px;
  top: -8px;
  right: -8px;
}

/* En tu archivo styles.css */

/* Estilos para el contenedor del ícono del carrito */
.navbar-tool-icon-box {
  position: relative;
  display: inline-block;
  padding: 5px;
}

/* Estilos para el círculo rojo que indica la cantidad de productos en el carrito */
.navbar-tool-label {
  position: absolute;
  top: -5px;
  right: -10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 3px 6px;
  font-size: 12px;
}

/* Estilos para el ícono del carrito */
.navbar-tool-icon {
  /* Añade tus estilos personalizados para el ícono del carrito aquí */
}