.vehicle-list {
    margin: 30px;
    max-height: 600px;
    overflow-y: auto;
}


.input-search {
    margin-bottom: 20px;
    width: 97%;
    height: 25px;
}

.vehicle-item {
    margin: 10px 0;
    width: 100%;
    margin-bottom: 8%;
    height: 100px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    background-color: #f0f0f0;
}

.vehicle-item button {
    border: none;
    background: none;
    cursor: pointer;
    width: 100%;
    height: 100%;
}

.vehicle-item img {
    width: 100px;
    height: 100px;
}

.vehicle-item .info-container {
    flex: 1;
    min-width: 70%;
    padding-left: 10px;
    align-items: flex-start;
    justify-content: flex-start;
}



